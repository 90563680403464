<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>优惠券列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>优惠券名称:</span>
      <el-input v-model.trim="list.couponName" placeholder="请输入优惠券名称"></el-input>
      <span>领取方式:</span>
      <el-select
        clearable
        @clear="onClear(1)"
        v-model="list.receiveWay"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>状态:</span>
      <el-select clearable @clear="onClear(2)" v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>所属分类:</span>
      <el-select v-model="list.cateId" placeholder="请选择">
        <el-option
          v-for="item in cateOptions"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getCouponList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push(`/addCoupon/0`)"
        v-if="$store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      >
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column align="center" type="selection" width="width"></el-table-column>
      <el-table-column align="center" prop="couponId" label="优惠券ID"></el-table-column>
      <el-table-column
        align="center"
        prop="couponName"
        label="优惠券名称"
      ></el-table-column>
      <el-table-column align="center" prop="cateName" label="所属分类"></el-table-column>
      <el-table-column
        align="center"
        prop="couponPrice"
        label="优惠券价格"
      ></el-table-column>
      <el-table-column align="center" prop="strScope" label="使用范围"></el-table-column>
      <el-table-column
        align="center"
        prop="strTimeType"
        label="有效期类型"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strReceiveWay"
        label="领取方式"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="getCount"
        label="领取数量"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="stock"
        label="	发放数量"
       
      ></el-table-column>

      <el-table-column
        align="center"
        prop="addTime"
        label="	添加时间"
        width="200"
      ></el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态"></el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.status === 2 &&
              $store.state.powerList.indexOf('coupon:info:list:update') !== -1
            "
            @click="onChangeStatus(row.couponId, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.status === 1 &&
              $store.state.powerList.indexOf('coupon:info:list:update') !== -1
            "
            @click="onChangeStatus(row.couponId, 2)"
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="$router.push(`/addCoupon/${row.couponId}`)"
            v-if="$store.state.powerList.indexOf('coupon:info:list:edit') !== -1"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onDelRow(row.couponId)"
            v-if="$store.state.powerList.indexOf('coupon:info:list:update') !== -1"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="goCouponQRCode(row.couponId)"
            >查看二维码</el-button
          >

        <template v-if="row.strReceiveWay != '定时发放'">
          <el-button type="primary" size="mini" @click="couponThrowPhone(row.couponId)"
            >手机号发放</el-button
          >
          <el-button type="primary" size="mini" @click="$router.push('/couponCodeList/'+ row.couponId)"
            >查看券码</el-button
          >
        </template>  

          <el-dialog
            title="优惠券二维码"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
            style="background: url()"
          >
            <el-image style="width: 330px; height: 330px" :src="QRCode" :fit="fit">
            </el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog
            title="手机号发放"
            :visible.sync="isInputPhone"
            width="30%"
            @close="handleClosePhone"
            style="background: url()"
          >
            <el-tag
              closable
              :key="index"
              v-for="(tag, index) in dynamicTags"
              :disable-transitions="false"
              @close="handleCloseTag()"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputPhoneVal"
              maxlength="11"
              ref="saveTagInput"
              size="medium"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput"
              >+点击输入手机号</el-button
            >
            <div style="color: red">
              tips:输入完一个手机号后按回车自动输入下一个手机号
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isInputPhone = false">取 消</el-button>
              <el-button type="primary" @click="submitPhoneNum()">确 定</el-button>
            </span>
          </el-dialog>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAll(1)"
        v-if="$store.state.powerList.indexOf('coupon:info:list:update') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAll(2)"
        v-if="$store.state.powerList.indexOf('coupon:info:list:update') !== -1"
        >批量下架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAll(0)"
        v-if="$store.state.powerList.indexOf('coupon:info:list:update') !== -1"
        >批量删除</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  couponList,
  changeStatus,
  couponQRCode,
  couponAllCateAPI,
  couponThrowPhone,
} from "../../../api/coupon";
export default {
  name: "couponList",
  data() {
    return {
      phoneArr: [],
      dynamicTags: [],
      inputPhoneVal: "",
      inputVisible: false,
      fit: "fill",
      cateOptions: [],
      tableData: [],
      pagination: {},
      ids: "",
      options: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册领取",
        },
      ],
      options1: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        couponName: "",
        receiveWay: null,
        status: null,
        cateId: null,
      },
      couponId: "",
      dialogVisible: false,
      QRCode: "",
      isInputPhone: false,
    };
  },
  created() {
    this.getCouponList();
    this.getCate();
  },
  methods: {
    submitPhoneNum() {
      this.requestPhoneNum();
    },
    couponThrowPhone(couponId) {
      this.couponId = couponId;
      this.isInputPhone = true;
      // this.requestPhoneNum()
    },
    async requestPhoneNum(couponId) {
      if(this.phoneArr.length <= 0){
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return
      }
      let parmas = {
        couponId: this.couponId,
        phone: this.phoneArr.join(),
      };
      const { data } = await couponThrowPhone(parmas);
      this.$message({
        message: data.msg,
      });
    },
    async getCate() {
      const { data } = await couponAllCateAPI();
      console.log(data, "data");
      this.cateOptions = data.data;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    downLoadQRCode() {
      //  var url = this.QRCode;
      //  var aLink = document.createElement("a");
      //  aLink.style.display = "none";
      //  aLink.href = url;
      //  aLink.setAttribute("download", '优惠券二维码');
      //  document.body.appendChild(aLink);
      //  aLink.click();
      //  document.body.removeChild(aLink); //下载完成移除元素
    },
    async goCouponQRCode(couponId) {
      this.dialogVisible = true;
      const { data } = await couponQRCode({
        couponId: couponId,
      });
      if (data.code == 0) {
        console.log(data.msg);
        this.QRCode = data.msg;
        console.log("图片", this.QRCode);
      }
    },
    async getCouponList() {
      const { data } = await couponList(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(id, status) {
      console.log(id, status);
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getCouponList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getCouponList();
    },
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleClosePhone(){
      this.dynamicTags = []
      this.phoneArr = []
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let reg = /^1\d/;
      if (!reg.test(this.inputPhoneVal) || this.inputPhoneVal.length !== 11) {
        return this.$message("请输入正确的手机号");
      }
      let inputValue = this.inputPhoneVal;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.inputVisible = true;
      this.phoneArr.push(this.inputPhoneVal);

      this.inputPhoneVal = "";
      console.log(this.phoneArr.join());
      //  this.$nextTick((_) => {
      //   this.$refs.saveTagInput.$refs.input.focus();
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
}
</style>
